import type {
  ToastConfig,
  ToastContainerElement
} from '@pocketoutdoormedia/toast/dist/src/types';
import { useAmplitudeFlags } from 'helpers/hooks/useAmplitudeFlags';
import { FEATURE_FLAGS } from 'lib/amplitude/constants';
import { useEffect } from 'react';

export const showToast = async (config: ToastConfig) => {
  const { showToast: show } = await import('@pocketoutdoormedia/toast');

  show(config);
};

export const showEmailVerificationWarning = async () => {
  // We must first import this package so that the email-verify-warning-notification package
  // works properly because it depends on the main toast package
  await import('@pocketoutdoormedia/toast');
  const { VerifyEmailToasts } = await import(
    '@pocketoutdoormedia/email-verify-notifications'
  );

  const verifyEmailToastsInstance = VerifyEmailToasts.getInstance();

  verifyEmailToastsInstance.initializeToasts();
};

export const ToastContainer = () => {
  const {
    data: {
      [FEATURE_FLAGS.accountsEmailVerification]: accountsEmailVerification
    }
  } = useAmplitudeFlags();

  useEffect(() => {
    if (accountsEmailVerification.isEnabled) {
      showEmailVerificationWarning();
    }
  }, [accountsEmailVerification]);

  return (
    <div className="relative">
      <out-toast-container />
    </div>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'out-toast-container': ToastContainerElement;
    }
  }
}
