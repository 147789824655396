import type { FeatureFlags } from '../types';
// The values of this object must correspond to real Amplitude flag keys
export const FEATURE_FLAGS = {
  accountsEmailVerification: 'accounts-email-verification',
  showLiveEventsErrorPage: 'show-live-events-error-page'
} as const;

export const DEFAULT_FLAGS: FeatureFlags = Object.values(FEATURE_FLAGS).reduce(
  (acc, flag) => ({
    ...acc,
    [flag]: {
      isEnabled: true
    }
  }),
  {} as FeatureFlags
);

// This ensures us that we only fetch the flags that we are using in the app
export const AMPLITUDE_FLAGS_KEYS = Object.values(FEATURE_FLAGS);

export const AMPLITUDE_EXPERIMENT_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_API_KEY;
