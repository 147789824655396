import memoize from 'lodash/memoize';
import debounce from 'lodash/debounce';
import type { DebouncedFunc } from 'lodash';

export type MemoizeDebouncedFunction<
  F extends (...args: unknown[]) => unknown
> = {
  func: F;
  resolver: (...args: Parameters<F>) => unknown;
  wait: number;
};

export const memoizeDebounce = <F extends (...args: unknown[]) => unknown>({
  func,
  resolver,
  wait
}: MemoizeDebouncedFunction<F>) => {
  const debounceMemo = memoize<(...args: Parameters<F>) => DebouncedFunc<F>>(
    () => debounce(func, wait),
    resolver
  );

  function wrappedFunction(...args: Parameters<F>): ReturnType<F> | undefined {
    return debounceMemo(...args)(...args);
  }

  return wrappedFunction;
};
