import {
  AnalyticsDefaultOptions,
  AnalyticsDefaultPayload,
  AnalyticsDefaultTrigger,
  AnalyticsProvider,
  AnalyticsTrigger
} from '@pocketoutdoormedia/analytics-provider';
import type { ComponentType } from 'react';
import { SITE_NAME } from 'helpers/constants';
import { memoizeDebounce } from 'helpers/utils/memoizeDebounce';

export const withAnalyticsProvider = <
  Props extends { pageProps?: { nextUrl: string } }
>(
  Component: ComponentType<Props>
) => {
  /* Somehow the analytics events are logged multiple times, it might be a problem on the @pocketoutdoormedia/analytics-provider package level.
  To avoid it and group all triggered events, it is wrapped in a debounce function.
  TODO: Implement debounced trigger function in the @pocketoutdoormedia/analytics-provider package */
  const DebouncedAnalyticsTrigger = memoizeDebounce({
    func: AnalyticsDefaultTrigger,
    resolver: (...payload) => payload?.[1]?.eventId,
    wait: 500
  });

  const ComponentWithAnalyticsProvider: ComponentType<Props> = (props) => (
    <AnalyticsProvider
      options={{
        ...AnalyticsDefaultOptions,
        analyticsJsUrl: process.env.NEXT_PUBLIC_METAROUTER_URL,
        writeKey: process.env.NEXT_PUBLIC_METAROUTER_ID
      }}
      payload={{
        ...AnalyticsDefaultPayload,
        props: { domain: SITE_NAME }
      }}
      trigger={DebouncedAnalyticsTrigger}
    >
      <AnalyticsTrigger event="page">
        <Component {...props} />
      </AnalyticsTrigger>
    </AnalyticsProvider>
  );

  return ComponentWithAnalyticsProvider;
};
