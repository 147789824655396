import { useQuery } from '@tanstack/react-query';
import { useUserProfile } from 'apis/user-auth/queries';
import { getCookie } from 'cookies-next';
import { RIVT_TOKEN_COOKIE_NAME } from 'helpers/middleware/constants';
import { fetchFeatureFlags } from 'lib/amplitude/client';
import { DEFAULT_FLAGS } from 'lib/amplitude/constants';
import type { FeatureFlags } from 'lib/amplitude/types';

const AMPLITUDE_FLAGS_QUERY_KEY = 'amplitude-flags';

export const useAmplitudeFlags = () => {
  const rivtToken = getCookie(RIVT_TOKEN_COOKIE_NAME);

  const { data: userProfile } = useUserProfile(rivtToken);

  const queryFn = async () => fetchFeatureFlags(userProfile.uuid);

  return useQuery<FeatureFlags>({
    enabled: !!userProfile,
    placeholderData: DEFAULT_FLAGS,
    queryFn,
    queryKey: [AMPLITUDE_FLAGS_QUERY_KEY]
  });
};
